<template>
    <div>
        <table width="100%" class="vs-table vs-table--tbody-table ">
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Inter Warehouse ID
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.sto_code }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Shipment Number
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.shipment_number }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Source Warehouse
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.src_warehouse.code + ' - ' + this.form.src_warehouse.name }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Delivery Note Number
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.delivery_note_number }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Arrival Date
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + formatDate(this.form.receive_date) }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Delivery Note Date
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + formatDate(this.form.delivery_date) }}
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    GR Document Number
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.code }}
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    Attachment
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' }}<a v-if="form.attachment_id > 0" :href="form.attachment.Url">Download Attachment</a>
                    <span v-else>No Attachment</span>
                </td>
            </tr>
            <tr>
                <td class="td vs-table--td" style="padding: 5px">
                    Note
                </td>
                <td class="td vs-table--td" style="padding: 5px">
                    {{ ': ' + this.form.note }}
                </td>
            </tr>
        </table>

        <table width="100%" class="vs-table vs-table--tbody-table print">
            <thead class="vs-table--thead">
                <tr>
                    <th style="text-align: center;" colspan="2">Item</th>
                    <th style="text-align: center;" colspan="2">Conversion</th>
                    <th style="text-align: center;" colspan="4">Batch/ED</th>
                    <th style="text-align: center;" colspan="2">Put Away</th>
                </tr>
                <tr>
                    <th>
                        <div>SKU Code</div>
                    </th>
                    <th>
                        <div>SKU Description</div>
                    </th>
                    <th>HU</th>
                    <th>Qty</th>
                    <th>Qty</th>
                    <th>Batch #</th>
                    <th>Expired Date</th>
                    <th>Check Status</th>
                    <th>Qty</th>
                    <th>Suggested Warehouse Area</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item, index) in form.stock_relocation_receive_line" :key="index">
                    <td :rowspan="item.lineCount"
                        v-if="index == 0 || form.stock_relocation_receive_line[index - 1].stock_relocation_receive_line_id != form.stock_relocation_receive_line[index].stock_relocation_receive_line_id"
                        class="td vs-table--td" style="padding: 5px">
                        <span>{{ item.sku_code }}</span>
                    </td>
                    <td :rowspan="item.lineCount"
                        v-if="index == 0 || form.stock_relocation_receive_line[index - 1].stock_relocation_receive_line_id != form.stock_relocation_receive_line[index].stock_relocation_receive_line_id"
                        class="td vs-table--td" style="padding: 5px">
                        <span>{{ item.item_name }}</span>
                    </td>
                    <td :rowspan="item.convCount"
                        v-if="index == 0 || form.stock_relocation_receive_line[index - 1].conversion_key != form.stock_relocation_receive_line[index].conversion_key"
                        class="td vs-table--td" style="padding: 5px">
                        <span>{{ item.conversion_unit }}</span>
                    </td>
                    <td :rowspan="item.convCount"
                        v-if="index == 0 || form.stock_relocation_receive_line[index - 1].conversion_key != form.stock_relocation_receive_line[index].conversion_key"
                        class="td vs-table--td" style="padding: 5px">
                        <span>{{ item.conversion_quantity }}</span>
                    </td>
                    <td :rowspan="item.checkCount" v-if="index == 0 || form.stock_relocation_receive_line[index - 1].stock_relocation_receive_line_conversion_id != form.stock_relocation_receive_line[index].stock_relocation_receive_line_conversion_id"
                        class="td vs-table--td" style="padding: 5px">
                        <span>{{ item.check_quantity }}</span>
                    </td>
                    <td :rowspan="item.checkCount" v-if="index == 0 || form.stock_relocation_receive_line[index - 1].stock_relocation_receive_line_conversion_id != form.stock_relocation_receive_line[index].stock_relocation_receive_line_conversion_id"
                        class="td vs-table--td" style="padding: 5px">
                        <span>{{ item.batch }}</span>
                    </td>
                    <td :rowspan="item.checkCount" v-if="index == 0 || form.stock_relocation_receive_line[index - 1].stock_relocation_receive_line_conversion_id != form.stock_relocation_receive_line[index].stock_relocation_receive_line_conversion_id"
                        class="td vs-table--td" style="padding: 5px">
                        <span>{{ formatDate(item.expired_date) }}</span>
                    </td>
                    <td :rowspan="item.checkCount" v-if="index == 0 || form.stock_relocation_receive_line[index - 1].stock_relocation_receive_line_conversion_id != form.stock_relocation_receive_line[index].stock_relocation_receive_line_conversion_id"
                        class="td vs-table--td" style="padding: 5px">
                        <span>{{ checkOption[item.status_damage].name }}</span>
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        <div>{{ item.quantity }}</div>
                    </td>
                    <td class="td vs-table--td" style="padding: 5px">
                        <div>{{ item.warehouse_area.name }}</div>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="flex justify-center w-full pt-6 print-button">
            <vs-button style="margin:5px" color="primary" @click="handlePrint()">Print</vs-button>
            <vs-button style="margin:5px" color="primary" @click="handleBack()">Exit</vs-button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
export default {
    components: {
    },
    mounted() {
        this.id = this.$route.params.id;
        this.mode = this.$route.params.mode;
        this.initData();
    },
    data() {
        return {
            checkOption: [{
                id: 0,
                name: "Passed"
            }, {
                id: 1,
                name: "Damaged"
            },{
                id: 2,
                name: "Expired"
            }],
            id: null,
            attachment: null,
            form: {
                gr_document_number: null,
                note: null,
                shipment_number: null,
                delivery_note_number: null,
                stock_relocation_receive_line: null,
            },
        }
    },
    methods: {
        async handlePrint() {
            await window.print();
        },
        handleBack() {
            this.$vs.loading();
            this.$router.push({
                name: this.$store.state.inbound.interWarehouse.baseRouterName,
            });
            this.$vs.loading.close();
        },
        initData() {
            this.$vs.loading();
            this.$http
                .get(`${this.$store.state.inbound.interWarehouse.baseUrlPath}/receive/put-away/edit-dto/${this.id}`)
                .then((resp) => {
                    if (resp.code == 200) {
                        this.form = resp.data;
                        this.form.stock_relocation_receive_line.forEach(l => {
                            l.lineCount = 0;
                            l.convCount =0;
                            l.checkCount =0;
                            this.form.stock_relocation_receive_line.forEach(item => {
                                if (l.stock_relocation_receive_line_id == item.stock_relocation_receive_line_id) {
                                    l.lineCount++;
                                }
                                if (l.conversion_key == item.conversion_key) {
                                    l.convCount++;
                                }
                                if (l.stock_relocation_receive_line_conversion_id == item.stock_relocation_receive_line_conversion_id) {
                                    l.checkCount++;
                                }
                            })
                        })
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
        formatDate: () => {
            return (val) => {
                const momentDate = moment.utc(val);
                return momentDate.format('DD MMM YYYY');
            };
        },
        formatLocalNumber: () => {
            return (val) => {
                return Number(val).toLocaleString("id-ID");
            };
        },
    },
}
</script>

<style>
/* Set the page size to A4 and the orientation to landscape */
@page {
    size: A4 landscape;
}

/* Set the font size to 12px for printing */
@media print {
    body {
        font-size: 12px;
        background: none !important;
    }

    * {
        background: none !important;
    }

    header,
    footer,
    .print-button,
    #content-area * :not(.content-wrapper *) {
        display: none !important;
        visibility: hidden !important;
    }

    .router-view,
    .router-content {
        padding: 0 !important;
        margin: 0 !important;
    }
}

table.print {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin-top: 20px;
}

table.print td,
table.print th {
    border: 1px solid #abab !important;
    text-align: left;
    padding: 8px;
}

table.print td>table * {
    border: 0px !important;
}
</style>
